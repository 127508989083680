@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*,
body {
  font-family: "Poppins" !important;
}

:root {
  --main-color: #E47500;
  --dark-main-color: #c96800;
}

.card {
  transition: background-color 0.3s ease;
}

/* .card:hover {
  background-color: #fff !important;
} */

.videoImage {
  transition: transform 0.3s ease;
}

.videoImage:hover {
  transform: scale(1.05);
}

.partnerItem {
  transition: transform 0.3s ease;
}

.partnerItem:hover {
  transform: scale(1.05);
}

.teamMember {
  transition: transform 0.3s ease;
}

.teamMember:hover {
  transform: scale(1.05);
}

.social-icons-div {
  display: flex;
  gap: 5%
}

.social-icon {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1%;
  cursor: pointer;
  color: var(--main-color);
  border: 2px solid var(--main-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.social-icon-white {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1%;
  cursor: pointer;
  color: white;
  border: 2px solid white;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.social-icon:hover {
  background-color: var(--main-color);
  color: white;
}

.social-icon-white:hover {
  background-color: white;
  color: var(--main-color);
}

input,
textarea {
  transition: border-color 0.3s ease;
}

input:focus,
textarea:focus {
  border-color: #FF7A00;
}

button:hover {
  background-color: #e66900;
}


@media (max-width: 599px) {
  .pricingCard {
    margin-bottom: 20px;
  }

  .featuresTable {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .featureRow {
    display: table-row;
  }
}

@media (min-width: 600px) and (max-width: 1023px) {
  .pricingCard {
    min-width: 200px;
  }
}

.pricingButton:hover {
  background-color: #e66900;
}


@media (max-width: 599px) {
  .addonsTable {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .addonsTableRow {
    display: table-row;
  }
}

@media (min-width: 600px) and (max-width: 1023px) {
  .addonsTable {
    width: 100%;
  }
}

.addonsTableCell {
  padding: 10px;
}

.addonsTableHeader {
  background-color: #f8f8f8;
  font-weight: 600;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.addonsTableRow {
  border-bottom: 1px solid #ddd;
}

.addonsServiceName {
  font-weight: 500;
}

.addonsServicePrice {
  text-align: right;
  font-weight: 500;
}


/* Header.css */
.nav-item-light::after,
.drawer-nav-item::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #000;
  transition: width 0.3s;
  margin-top: 5px;
}

.nav-item-light:hover::after,
.drawer-nav-item:hover::after {
  width: 100%;
}


.nav-item::after,
.drawer-nav-item::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #fff;
  transition: width 0.3s;
  margin-top: 5px;
}

.nav-item:hover::after,
.drawer-nav-item:hover::after {
  width: 100%;
}

.industryItem:hover {
  background-color: white !important;
  box-shadow: 0 4px 94px 0 #00000026 !important;
}

.darkIndustryItem:hover {
  background-color: #000000 !important;
  box-shadow: 0 4px 94px 0 #00000026 !important;
}




@keyframes pulse {
  0% {
    transform: scale(.1);
    opacity: 0;
  }

  50% {
    opacity: .4;
  }

  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}


/* light mode */

.loading {
  background-color: rgb(0, 0, 0);
  border-radius: 50%;
  position: absolute;
  margin: auto auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 70px;
  height: 70px;
}

.loading:nth-child(1) {
  animation: pulse 2s infinite;
}

.loading:nth-child(2) {
  animation: pulse 2s infinite .3s;
}

.loading:nth-child(3) {
  text-align: center;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 40px;
  width: 30px;
  height: 30px;
}




/* dark mode */

.loading-dark {
  background-color: rgb(146, 146, 146);
  border-radius: 50%;
  position: absolute;
  margin: auto auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 70px;
  height: 70px;
}
